




body {
  line-height: 1.7;
  color: #8c92a0;
  font-weight: 300;
  font-size: 16px; }





  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password a {
    color: #167bff;
  }










::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

.text-black {
  color: #000; }

.site-wrap:before {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.offcanvas-menu .site-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden; }
  .offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible; }

.btn {
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out !important;
  -o-transition: 0.2s all ease-in-out !important;
  transition: 0.2s all ease-in-out !important;
  top: 0;
  letter-spacing: .05em; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-secondary {
    background-color: #e6e7e9;
    border-color: #e6e7e9;
    color: #000; }
  .btn.btn-sm {
    font-size: 14px !important;
    padding: 10px 20px !important; }
  .btn.btn-primary {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: .2em; }
  .btn:hover {
    -webkit-box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    top: -2px; }

.form-control {
  height: 43px; }
  .form-control:active, .form-control:focus {
    border-color: #7971ea; }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.site-section {
  padding: 2.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 5em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  font-size: 30px;
  color: #25262a;
  position: relative; }
  .site-section-heading:before {
    content: "";
    left: 0%;
    top: 0;
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }
  .site-section-heading.text-center:before {
    content: "";
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }

.border-top {
  border-top: 1px solid #edf0f5 !important; }

.site-footer {
  padding: 4em 0; }
  .site-footer ul li {
    margin-bottom: 10px; }
    .site-footer ul li a {
      color: #5c626e; }
      .site-footer ul li a:hover {
        color: #7971ea; }
  .site-footer .footer-heading {
    font-size: 20px;
    color: #25262a; }

/* Navbar */
.site-navbar {
  background: #fff;
  margin-bottom: 0px;
  z-index: 1999;
  position: relative; }
  .site-navbar.transparent {
    background: transparent; }
  .site-navbar .site-navbar-top {
    /* border-bottom: 1px solid #f3f3f4; */
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      .site-navbar .site-navbar-top {
        padding-top: 40px;
        padding-bottom: 40px; } }
  .site-navbar .site-search-icon a span {
    display: inline-block;
    margin-right: 10px; }
  .site-navbar .site-search-icon a:hover {
    text-decoration: none; }
  .site-navbar .site-logo a {
    text-transform: uppercase;
    color: #25262a;
    letter-spacing: .2em;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 2px solid #25262a; */
   }
    .site-navbar .site-logo a:hover {
      text-decoration: none; }
  .site-navbar a {
    color: #868995; }
    .site-navbar a:hover {
      color: #3d3f45; }
  .site-navbar .icon {
    font-size: 20px; }
  .site-navbar .site-top-icons ul, .site-navbar .site-top-icons ul li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .site-navbar .site-top-icons ul li {
    display: inline-block; }
    .site-navbar .site-top-icons ul li a {
      margin-right: 10px; }
      .site-navbar .site-top-icons ul li a.site-cart {
        display: block;
        position: relative; }
        .site-navbar .site-top-icons ul li a.site-cart .count {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -15px;
          margin-top: -20px;
          font-size: 13px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 50%;
          display: block;
          text-align: center;
          background: black;
          color:white;
          -webkit-transition: .2s all ease-in-out;
          -o-transition: .2s all ease-in-out;
          transition: .2s all ease-in-out; }
      .site-navbar .site-top-icons ul li a:hover .count {
        -webkit-box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        margin-top: -22px; }
    .site-navbar .site-top-icons ul li:last-child a {
      padding-right: 0; }
  .site-navbar .site-top-icons ul .site-menu-toggle {
    font-size: 25px;
    position: relative;
    top: 2px;
    margin-left: 20px; }
  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #f3f3f4 !important; }
  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0; }
    .site-navbar .site-navigation .site-menu .active > a {
      color: #7971ea; }
    .site-navbar .site-navigation .site-menu a {
      text-decoration: none !important;
      font-size: 15px;
      display: inline-block; }
    .site-navbar .site-navigation .site-menu > li {
      display: inline-block;
      padding: 6px 16px; }
     
      .site-navbar .site-navigation .site-menu > li > a {
        /* padding: 10px 10px; */
        text-transform: uppercase;
        letter-spacing: .05em;
        color: white;
        font-size: 14px;
        font-weight: bold;

        text-decoration: none !important; } 
        @media (max-width: 768px) {
        .site-navbar .site-navigation .site-menu > li > a {
          /* padding: 10px 4px; */
          text-transform: uppercase;
          /* letter-spacing: .05em; */
          color: white;
          font-size: 14px;
          font-weight: bold;
        }
        .site-navigation  {
          margin-left : -60px !important;
          padding-right:  60px !important;
           }
      }
        .site-navbar .site-navigation .site-menu > li > a:hover {
          color: #7971ea; }
    .site-navbar .site-navigation .site-menu .has-children {
      position: relative; }
      .site-navbar .site-navigation .site-menu .has-children > a {
        position: relative;
        padding-right: 20px; }
        .site-navbar .site-navigation .site-menu .has-children > a:before {
          position: absolute;
          content: "\e313";
          font-size: 16px;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: 'icomoon'; }
      .site-navbar .site-navigation .site-menu .has-children .dropdown {
        visibility: hidden;
        opacity: 0;
        top: 100%;
        position: absolute;
        text-align: left;
        border-top: 2px solid #7971ea;
        -webkit-box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        border-left: 1px solid #edf0f5;
        border-right: 1px solid #edf0f5;
        border-bottom: 1px solid #edf0f5;
        padding: 0px 0;
        margin-top: 20px;
        margin-left: 0px;
        background: #fff;
        -webkit-transition: 0.2s 0s;
        -o-transition: 0.2s 0s;
        transition: 0.2s 0s; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown a {
          font-size: 16px;
          text-transform: none;
          letter-spacing: normal;
          -webkit-transition: 0s all;
          -o-transition: 0s all;
          transition: 0s all; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
          color: #7971ea !important; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
          list-style: none;
          padding: 0;
          margin: 0;
          min-width: 200px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
            padding: 9px 20px;
            display: block; }
            .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
              background: #f4f5f9;
              color: #25262a; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
            content: "\e315";
            right: 20px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
            left: 100%;
            top: 0; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
            background: #f4f5f9;
            color: #25262a; }
      .site-navbar .site-navigation .site-menu .has-children a {
        text-transform: uppercase; }
      .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
        color: #7971ea; }
      .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
        cursor: pointer; }
        .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
          -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
          margin-top: 0px;
          visibility: visible;
          opacity: 1; }

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  .site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
      float: right;
      margin-top: 8px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
        font-size: 40px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
          color: #25262a; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 20px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
        display: inline-block;
        text-transform: uppercase;
        color: #25262a;
        letter-spacing: .2em;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid #25262a; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
          text-decoration: none; }
  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px; }
  .site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
    .site-mobile-menu .site-nav-wrap a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: #212529; }
      .site-mobile-menu .site-nav-wrap a:hover {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap li {
      position: relative;
      display: block; }
      .site-mobile-menu .site-nav-wrap li.active > a {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
        background: #f8f9fa; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
        font-size: 12px;
        z-index: 20;
        font-family: "FontAwesome";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-180deg);
        -ms-transform: translate(-50%, -50%) rotate(-180deg);
        transform: translate(-50%, -50%) rotate(-180deg);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .site-mobile-menu .site-nav-wrap > li {
      display: block;
      position: relative;
      float: left;
      width: 100%; }
      .site-mobile-menu .site-nav-wrap > li > a {
        padding-left: 20px;
        font-size: 20px; }
      .site-mobile-menu .site-nav-wrap > li > ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        .site-mobile-menu .site-nav-wrap > li > ul > li {
          display: block; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > a {
            padding-left: 40px;
            font-size: 16px; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
            padding: 0;
            margin: 0; }
            .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
              display: block; }
              .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
                font-size: 16px;
                padding-left: 60px; }

/* Blocks */
.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .site-blocks-cover, .site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh - 174px); }
  .site-blocks-cover h1 {
    font-size: 30px;
    font-weight: 900;
    color: #000; }
    @media (min-width: 768px) {
      .site-blocks-cover h1 {
        font-size: 50px; } }
  .site-blocks-cover p {
    color: #333333;
    font-size: 20px;
    line-height: 35px; }
  .site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5; }

.site-blocks-1 {
  border-bottom: 1px solid #edf0f5; }
  .site-blocks-1 .divider {
    position: relative; }
    .site-blocks-1 .divider:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      right: 10px;
      background: #edf0f5; }
    .site-blocks-1 .divider:last-child:after {
      display: none; }
  .site-blocks-1 .icon span {
    position: relative;
    color: #7971ea;
    top: -10px;
    font-size: 50px;
    display: inline-block; }
  .site-blocks-1 .text h2 {
    color: #25262a;
    letter-spacing: .05em;
    font-size: 18px; }
  .site-blocks-1 .text p:last-child {
    margin-bottom: 0; }

.site-blocks-2 .block-2-item {
  display: block;
  position: relative; }
  .site-blocks-2 .block-2-item:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
    background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .site-blocks-2 .block-2-item .image {
    position: relative;
    margin-bottom: 0;
    overflow: hidden; }
    .site-blocks-2 .block-2-item .image img {
      margin-bottom: 0;
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
  .site-blocks-2 .block-2-item .text {
    z-index: 2;
    bottom: 0;
    padding-left: 20px;
    position: absolute;
    width: 100%; }
    .site-blocks-2 .block-2-item .text > span, .site-blocks-2 .block-2-item .text h3 {
      color: #fff; }
    .site-blocks-2 .block-2-item .text > span {
      font-size: 12px;
      letter-spacing: .1em;
      font-weight: 900; }
    .site-blocks-2 .block-2-item .text h3 {
      font-size: 40px; }
  .site-blocks-2 .block-2-item:hover .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }

.block-3 .owl-stage {
  padding-top: 40px;
  padding-bottom: 40px; }

.block-3 .owl-nav {
  position: relative;
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  .block-3 .owl-nav .owl-prev, .block-3 .owl-nav .owl-next {
    position: relative;
    display: inline-block;
    padding: 20px;
    font-size: 30px;
    color: #5c626e; }
    .block-3 .owl-nav .owl-prev:hover, .block-3 .owl-nav .owl-next:hover {
      color: #25262a; }
    .block-3 .owl-nav .owl-prev.disabled, .block-3 .owl-nav .owl-next.disabled {
      opacity: .2; }

.block-4 {
  -webkit-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .block-4 .block-4-text h3 {
    font-size: 20px;
    margin-bottom: 0; }
    .block-4 .block-4-text h3 a {
      text-decoration: none; }

.block-5 ul, .block-5 ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5; }

.block-5 ul li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 15px;
  color: #25262a; }
  .block-5 ul li:before {
    top: 0;
    font-family: "icomoon";
    content: "";
    position: absolute;
    left: 0;
    font-size: 20px;
    line-height: 1;
    color: #7971ea; }
  .block-5 ul li.address:before {
    content: "\e8b4"; }
  .block-5 ul li.email:before {
    content: "\f0e0"; }
  .block-5 ul li.phone:before {
    content: "\f095"; }

.block-6 {
  display: block; }
  .block-6 img {
    display: block; }
  .block-6 h3 {
    font-size: 18px; }
  .block-6 p {
    color: #737b8a; }

.block-7 .form-group {
  position: relative; }

.block-7 .form-control {
  padding-right: 96px; }

.block-7 .btn {
  position: absolute;
  width: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 3px; }

.block-8 .post-meta {
  color: #c4c7ce; }

.block-8 .block-8-sep {
  margin-left: 10px;
  margin-right: 10px; }

.site-blocks-table {
  overflow: auto; }
  .site-blocks-table .product-thumbnail {
    width: 200px; }
  .site-blocks-table thead th {
    padding: 30px;
    text-align: center;
    border-width: 1px !important;
    vertical-align: middle;
    color: #212529;
    font-size: 18px; }
  .site-blocks-table td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    color: #212529; }
  .site-blocks-table tbody tr:first-child td {
    border-top: 1px solid #7971ea !important; }

.site-block-order-table th {
  border-top: none !important;
  border-bottom-width: 1px !important; }

.site-block-order-table td, .site-block-order-table th {
  color: #000; }

.site-block-top-search {
  position: relative; }
  .site-block-top-search .icon {
    position: absolute;
    right: -2.5%;
    top: 39%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .site-block-top-search input {
    padding-left: 40px;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
    .site-block-top-search input:focus, .site-block-top-search input:active {
      padding-left: 25px; }

.site-block-27 ul, .site-block-27 ul li {
  padding: 0;
  margin: 0; }

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px; }
  .site-block-27 ul li a, .site-block-27 ul li span {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .site-block-27 ul li.active a, .site-block-27 ul li.active span {
    background: #7971ea;
    color: #fff;
    border: 1px solid transparent; }

#slider-range {
  height: 8px; }
  #slider-range .ui-slider-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none !important;
    background: #7971ea; }
    #slider-range .ui-slider-handle:focus, #slider-range .ui-slider-handle:active {
      outline: none; }
  #slider-range .ui-slider-range {
    background-color: #7971ea; }

.color-item .color {
  width: 14px;
  height: 14px; }

.block-16 figure {
  position: relative; }
  .block-16 figure .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 40px;
    width: 90px;
    height: 90px;
    background: #fff;
    display: block;
    border-radius: 50%;
    border: none; }
    .block-16 figure .play-button:hover {
      opacity: 1; }
    .block-16 figure .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-50%, -45%);
      -ms-transform: translate(-50%, -45%);
      transform: translate(-50%, -45%); }

.block-38 .block-38-header .block-38-heading {
  color: #000;
  margin: 0;
  font-weight: 300; }

.block-38 .block-38-header .block-38-subheading {
  color: #b3b3b3;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: .1em; }

.block-38 .block-38-header img {
  width: 120px;
  border-radius: 50%;
  margin-bottom: 20px; }


  @media  (max-width: 996px) {
    .block-none-cato{
  display:  none;
    }
  }

  .logo-img{
    width:350px;
    height:150px;
  }
  .js-logo-clone{
    /* padding: 28px 7px; */
  }
  .site-navbar .site-logo a{
    /* width:350px; */
    height:150px !important;
    padding :  60px 20px;
  }
  
  /* @media  (max-width: 400px) {
    .js-logo-clone{
      padding: 15px 7px;
    }
    .logo-img{
      width:200px;
      height:50px;
    }
  } */

  /* Admin */

.admin-data-container{
    /* width : 220px ; */
    background : rgb(74, 76, 78);
    height : 150px;
    margin :  3px 7px;

}

.admin-card{
  margin : 5px 0px
}
.site-section-heading:before {
  left: 45%  !important;
}


.color-th{
  color :  black
}


.pop-up-1{
    background : black ;
    color : white ;
    width : 500px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 40%;
    left : 32% ;
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.4;
}




@media  (max-width: 500px) {

  .pop-up-1{
    background : black ;
    color : white ;
    width : 300px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 30% !important;
    left : 8% !important;
    /* top : 60%; */
    /* left : 32% ; */
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.7;
}

}













.change-header-color{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
}

.change-header-color33{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
  display: none;
}

/* .change-header-color1{ */
  /* color:rgb(238, 2, 138) !important; */
/* } */



.change-header-color{
  /* color:rgb(0, 240, 255) !important; */
  color: #000 !important;
}

/* .change-header-color1:hover{
  color:rgb(0, 240, 255);
} */


.arrival{
  overflow: hidden;
  max-width : 600px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 500px !important;
  margin: 20px 10px !important;

  /* margin : 15px 10px;
  width : 600px;
  height:500px;    
  overflow: hidden; */

  /* max-width : 650px;
  overflow: hidden; */

}

.arrival-Photo{
  /* width : 650px ;
  height : 400px  !important;
  transition: transform 2s, filter 1.5s ease-in-out; */


  width : 600px;
    height:500px;    
    transition: all 2s  ease-in-out;

  

}
.arrival-Photo1{
  /* max-width : 650px !important; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  /* overflow: hidden; */
  max-width : 367px !important;
}
.arrival1{
  overflow: hidden;
  max-width : 367px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 400px !important;
  margin: 20px 10px !important;

}

.arrival-Photo11{
  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px 10px !important; */
  max-width : 367px !important;
  /* padding-left : 40px; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  overflow: hidden;
}

.arrival-Photo:hover{
  /* filter: brightness(90%); */
  transform: scale(1.1);

}
.arrival-Photo1:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}
.arrival-Photo11:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}

@media screen and (max-width: 1199px) {
  /* .arrival-Photo{ */
    /* margin : 0px auto; */
    /* margin-left : 30px; */
    /* width : 70% !important; */
    /* overflow: auto; */
    /* transform: scale(1.4); */

  /* } */
  .arrival-Photo1{
    margin : 0px auto;
    margin-top : 40px;
    /* margin-left : 30px; */
    width : 1010px !important;
    /* transform: scale(1.4); */

  }
}
@media screen and (max-width: 800px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 550px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 550px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 600px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 470px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 470px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 520px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 400px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 400px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 450px) {
  /* .arrival-Photo{
    margin : 0px 10px;
    margin-left : 10px;
    max-width : 310px !important;
    padding-left : 20px;

    
  } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    max-width : 310px !important;
    padding-left : 20px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
  /* .arrival1{
    margin: auto !important;
    width : 80%;
  } */
  .arrival1{
    max-width : 300px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 300px !important;
  margin  : auto;

  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px auto !important;
  max-width : 270px !important;
  padding-left : 10px;
  height : 420px !important;
  padding-bottom: 20px !important;
  transition: transform 2s, filter 1.5s ease-in-out; */
}
}
@media screen and (max-width: 310px) {
  
  .arrival1{
    max-width : 250px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 250px !important;
  margin  : auto;

}
}
.backk{
  background: url("assets/images/back.jpg");
  /* background-repeat:no-repeat; */

}
.HeaderMainImp{
  padding-bottom: 20px; 
  /* background: url("assets/images/header-bg-rpt.jpg"); */
  background: rgb(36, 163, 181);
  color :  white;
  background-repeat: repeat-x;
  

}
.HeaderMainImp > p{
  margin: 0px 30px; 
  padding: 10px 0px;
}
.mobile-head-menu{
    height : auto ; 
    width:  100% ;
    background-color : rgb(255, 255, 255) !important;
    z-index : -1;
    margin : 0px;
    padding : 0px;
    /* border-bottom: 3px solid black; */


}
.mobile-head-menu > li{
    list-style-type: none;
    padding: 18px 30px;
    border-bottom: 5px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(114, 103, 103);
}
.mobile-nav-border {
  font-size: 18px !important;
    /* border-bottom: 3px solid black; */
    /* width : 150px !important; */
    padding:0px 40px 6px 0px !important;
    color : black !important;
    font-weight: 500 !important;
}
.part2-nav{
  padding-bottom: 0px !important;
}
.nested-drop-nav{
  margin : 0px 0px 0px 20px;
  /* color : black !important; */
  font-weight: 500 !important;
  /* padding  : */
}
.nested-drop-nav > li{
  list-style-type: none;
  padding : 15px;
}

.mobile-site-hide-show{
  /* visibility: hidden; */
  background:rgb(245, 245, 245) !important;
  display: none;
}

.desktop-nav{
  position:absolute;
  width : 30%;
  z-index: 99999;
  margin-left : 30%;
  background-color : rgb(207, 248, 152) !important;
  /* display: none; */
}
.desktop-nav-drop{
  height : auto ; 
   width: 40%;
}
.desktop-nav-drop > li{
  list-style-type: none;
  padding : 20px 10px ;
  font-size: 22px !important;
  font-weight: bold;
}
.desktop-nav-drop > li > Link{
  border-bottom: 1px solid #167bff;
}
.site-menu  > li{
  transition: transform 2s, filter 1.5s ease-in-out !important;
  transform-origin: center center;

}
.site-menu > li:hover{
  background-color: rgb(233, 184, 184);
}
.nav-menu-hover-effect:hover .desktop-nav{
  display:none ;
}
/* .desktop-nav{
  display: none ;
}
.desktop-nav:hover{
  display: block ;
} */

#desktop-nav1{
  display: none ;
}
#desktop-nav1:hover{
  display: block ;
}
.nav-menu-hover-effect:hover{
  background-color: #737b8a;
}



 
.logo-design{
  padding-right:50px !important;

}

.input-search-mobile{

  /* margin-left: 30px !important; */
}




@media screen and (max-width: 1200px) {
  
 
  /* .nav-icon-mobile{
    display: none;
    visibility: hidden;
  } */
    .logo-design{
    /* padding-right:150px !important; */
    margin-top:-30px !important ;
    margin-left:-40px !important ;
  
  }
  }

@media screen and (max-width: 800px) {
  

  .input-search-mobile{
    margin-top:  80px !important;
  }
  .logo-design{
    margin-left:0px !important ;
  
  }
  }
  
@media screen and (max-width: 520px) {
  
.logo-design{
  /* padding-right:150px !important; */
  padding-left:0px !important ;
  padding-right: 0px !important;


}
.input-search-mobile{
  margin-left:30px !important ;
  padding : 7px 10px;
  /* margin-top: 10px !important; */
}
.nav-icon-mobile{
  margin-top: -35px !important;
}
.input-search-mobile{
  margin: auto !important;
  margin-top:  40px !important;
}
}

@media screen and (max-width: 820px) {
.site-navigation{
  display: none;
}
/* .mobile-site-hide-show{
  display:  block;
} */
.change-header-color33{
  display:  block;
  height : auto !important;
  font-size: 29px !important;
}
}

@media screen and (min-width: 820px) {

.mobile-site-hide-show{
  display:  none;
}
#mobile-site-hide-show11{
  display:  none !important;
}

}






.shoes-type-Photo11{
  padding-left: 30px;
  width: 700px;
  height : 300px;
}


@media screen and (max-width: 1220px) {

  .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}


/* @media screen and () { */

 
/* } */

@media only screen and (max-width: 1400px) and (min-width: 1230px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 600px;
    height : 300px;
  }
}

@media only screen and (max-width: 1220px) and (min-width: 900px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}

@media only screen and (max-width: 899px) and (min-width: 680px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 330px;
    height : 200px;
  }
}
@media only screen and (max-width: 681px) and (min-width: 581px) {
 .shoes-type-Photo11{
    padding-left: 0px !important;
    width: 280px;
    height : 180px;
  }
}

@media only screen and (max-width: 580px) and (min-width: 480px) {
 .shoes-type-Photo11{
    padding-left: 70px !important;
    padding-top  : 30px;
    width: 400px;
    height : 180px;
  }
}

@media only screen and (max-width: 479px) and (min-width: 390px) {
 .shoes-type-Photo11{
    padding-left: 35px !important;
    padding-top  : 30px;
    width: 330px;
    height : 180px;
  }
}

@media only screen and (max-width: 389px) and (min-width: 250px) {
 .shoes-type-Photo11{
    /* padding-left: 35px !important; */
    padding-top  : 30px;
    width: 300px;
    height : 180px;
  }
}
@media only screen and (max-width: 189px) and (min-width: 300px) {
 
.pop-up-1{
  /* background : black ;
  color : white ;
  width : 500px;
  padding : 5px 10px ;
  border-radius : 10px ;
  margin :  5px ;
  position : fixed ; */
  /* top : 220% !important; */
  /* left : 32% ;
  z-index : 99999 ;
  visibility: hidden;
  opacity: 0.4; */
}
}


.Shoes-Size-Button {
  max-height : 150px;
  overflow-y: scroll;
}


.Shoes-Size-Button > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 1px solid grey ; 
  background:  transparent;

}


.Shoes-Size-Button1 > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 2px solid grey ; 
  background:  transparent;

}




.details-img-size{
  height:140px !important;
  padding-left: 20px !important;
  width : 33%  !important;
  /* background-size: cover;
  background-position: center;
  background-image: none; */
}

.container-photo-detail{
  width : 500px;
  height:500px;    
  overflow: hidden;
  /* margin: 0 auto; */

}
#changes-Photo{
    width : 500px;
    height:500px;    
    transition: all 2s  ease-in-out;
    /* background-size: cover;
    background-position: center;
    background-image: none;
    transform-origin: center center; */
    /* filter: brightness(100%); */
    /* transition: transform 2s, filter 1.5s ease-in-out; */
}

/* #/: scale(1.5); */
  /* width :  700px !important;
  height  : 700px; */
  /* max-width : 500px; */
  /* filter: brightness(90%); */
  /* transform: scale(1.4); */
    /* max-width : 500px; */
    /* transform: translate(50%); */

/* } */

@media only screen and (max-width: 469px) and (min-width: 321px) {
  .container-photo-detail{
    width : 330px !important;
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 330px !important;
    height:400px !important;    

}

}

@media only screen and (max-width: 320px) and (min-width: 150px) {
  .container-photo-detail{
    width : 300px !important;
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 300px !important;
    height:400px !important;    

}

}

.product-detail-descr-rating {
    padding  : 2px;
    border-radius: 30px;
}
.product-detail-descr-rating > div{
    background-color: rgba(0, 0, 0, 0.178);
    /* max-width : 100px !important; */
    padding  : 20px 30px;
}
.product-detail-descr-rating > div > p{
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
}

.product-detail-descr-rating1 {
  margin : 0px;
  padding  : 2px;
  border-radius: 30px;
}
.product-detail-descr-rating1 > div{
  border-radius: 10px;
  border : 2px solid grey;
  border-top: none;
  height : auto !important;
  /* background-color: rgba(0, 0, 0, 0.178); */
  /* max-width : 100px !important; */
  padding  : 20px 30px;
}


.product-detail-descr-rating3 {
  margin : 0px;
  padding  : 2px;
  border : 2px solid grey;
  border-top: none;
  border-radius: 30px;
  color  : black !important;
  font-weight: bold !important;
}

.product-detail-descr-rating3  > div > h3{
  margin-top : -15px;
  /* padding: 0px; */
}


.Icon-start{
  color : rgba(192, 186, 186, 0.432) ;
}
.a1-start:hover{
  color : blue !important;
}
.a2-start:hover{
  color : rgb(231, 255, 145) !important;
}
.a3-start:hover{
  color : rgb(39, 169, 230) !important;
}
.a4-start:hover{
  color : rgb(170, 63, 63) !important;
}
.a5-start:hover{
  color : rgb(14, 167, 35) !important;
}
.root{
  width : 100% !important;
}

.display-none-icon{
  display: none;
}


/* @media only screen and (max-width: 200px) and (min-width: 850px) {
  .display-none-icon{
    display: none;
  }
} */


@media only screen and (max-width: 200px) and (min-width: 999px) {
  .nav-icon-mobile{
    padding-top : 50px !important; 
}
   .change-header-color{
    position: absolute;
    right  : 10%
   }
   .change-header-color1{
    position: absolute;
    right   : 15%
   }
   .change-header-color33{
    position: absolute !important;
    left  : 8%
   }
}

@media  only screen and (min-width: 526px) and (max-width: 800px){
.input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    /* margin-top: 80px !important; */
}
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
.site-block-top-search .icon {
    right: -3%;
    top: 23% !important;
     }
     .site-block-top-search input{
       padding-left: 0px !important;
     }
     .wsdsd {
      padding-top: 0px !important;
      margin-left: 14px !important;
     }
.site-navbar .site-navbar-top {
      height: 150px;
     }
  .input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 50px !important;
    /* margin-left: 14px !important; */
    
    /* margin-top: 80px !important; */
}
}

#view-at-hover{
  background-color: rgb(86, 97, 102) !important;
  visibility: hidden;
  transition: .2s all ease-in-out;
}
.view-btn-detail{
  padding : 10px 30px !important;
}

.hover-at-view{
  transition: .2s all ease-in-out;
  visibility: visible !important;
  
}
.hover-at-view:hover{
  background-color: skyblue !important;
}

.hover-product-card:hover{
  /* border: 1px solid grey; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  
}

.nav-icon-mobile45{
  padding-top: 25px !important;
  padding-left: 30px;
}

.dispaly-bllock-none{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 791px){
  .dispaly-bllock-none{
    display: block !important;
  }

.display-noen{
  display: none !important;
  
}

}
@media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }
  
}
@media  only screen and (min-width: 600px) and (max-width: 821px){
  .logo-img{
    margin-left: 91px;
  }
  .fglgiklkkllk1{
    display: none !important;
  }
  .fglgiklkkllk2{
    display: block !important;
  }

}
@media  only screen and (min-width: 500px) and (max-width: 768px){
  .logo-img{
    margin-left: 50% !important;
    width : 235px !important
  }
  .dispaly-bllock-none {
    position: absolute !important;
    right: -3% !important;
  }

}
/* @media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }

} */
@media  only screen and (min-width: 700px) and (max-width: 885px){
  .nav-icon-mobile45{
   padding-left: 0px !important;
  }

}
/* @media  only screen and (min-width: 717px) and (max-width: 991px){
  .logo-img{
    margin-left: 191px;
  }
 

} */

@media  only screen and (min-width: 586px) and (max-width: 716px){
 .logo-img{
      margin-left: 81px;
    width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 40px !important;
}

}

@media  only screen and (min-width: 400px) and (max-width: 585px){
 .logo-img{
      margin-left: 40px !important;
      width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 44px !important;
      padding-right: 0px !important;

}
.change-header-color33{
  padding-left: 20px;
}
.dispaly-bllock-none{
  margin-left: 40px;
}
}
@media  only screen and (min-width: 200px) and (max-width: 385px){
  .logo-img{
       margin-left: 37px;
       width : 240px;
  }
 }
@media (min-width: 576px){
.col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
}

@media  only screen and (min-width: 400px) and (max-width: 579px){
  .change-header-color33{
   font-size: 49px !important;
 }
}
@media  only screen and (min-width: 330px) and (max-width: 576px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 300px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  /* max-width :459px !important; */
  margin : auto;
 }
}

@media  only screen and (min-width: 330px) and (max-width: 400px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 270px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  max-width :329px !important;
  margin : auto;
 }
}

@media  only screen and (min-width: 230px) and (max-width: 329px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 250px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   padding-left: 40px !important;
   width : 230px !important;
 }
 .cart-width-change{
  width: 70px !important;
  position: absolute;
  right: 19%;
 }
 .input-width-change{
  width :309px !important;
 }
}

.border-0{
  padding-top: 20px !important;
}
.icon-search2{
   padding-top: 10px !important;
 }
 .logo-img {
  width: 163px;
    height: 67px;
}
.site-navbar .site-navbar-top {
  background: white;
  /* background: rgb(97 , 171 ,235); */
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.HeaderMainImp {
  height : 32px;
    padding-bottom: 0px;
    width: 100% !important;
}
.site-footer {
  padding: 0px;
}

#myDiv1234{
  display : none;
  width: 80% !important;
  height : 80% ;
  background-color: white;

}
#mainphoto{
  display : none;
  width: 100% !important;
  height : 100% ;
  background-color: rgb(190, 190, 190);

}
#myDiv23456{
  width: 70% !important;
  height : 80% ;
  position: absolute;
  right: 16%;
  top : 60%;
  z-index: 3;
}
#mainphoto2{
  width: 90% !important;
  height : 90% ;
  position: fixed;
  left: 3%;
  top : 5%;
  z-index: 5;
}
#photo33{
  /* right: 5%; */
  /* top : 180% !important; */
  position: fixed !important;
  z-index: 6;
  right: 2% !important;
  
  top: 7% !important;
}
@media  only screen and (min-width: 530px) and (max-width: 1029px){

  #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 5;
    height : 70% ;

  }
  #mainphoto2{
    /* right: 25%; */
    top : 5% !important;
    z-index: 3;
    /* height : 70% ; */

  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
  }
  #photo33{
    /* right: 5%; */
    /* top : 180% !important; */
    /* z-index: 3; */
    position: fixed;
  /* z-index: 4; */
  right: 2%;
  top: 8%;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
  #myDiv23456{
    right: 20%;
    top : 150% !important;
    z-index: 3;
    height: 60%;
    width : 90%;
  }
  #mainphoto2{
    /* right: 20%; */
    top : 10% !important;
    z-index: 3;

    left : 6% !important;
    width : 85% !important;
    height : 85% !important;
  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
    /* width : 90% !important; */

  }
  #photo33{
    position: fixed;
    /* z-index: 4; */
    right: .5%;
    top: 9% !important;

  }

  /* #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 3;
    height : 70% ;

  }
  #myDiv34567{
    right: 25%;
    top : 180% !important;
    z-index: 3;
  } */
  /* #myDiv23456 {
    width: 82% !important;
    height: 80%;
    position: absolute;
    right: 16%;
    top: 60%;
    z-index: 3;
}
  #myDiv1234{
    width: 90% !important;
  
  } */
  /* #myDiv23456{
    right: 3%;
    top : 180% !important;
    z-index: 3;
    height : 90% ;

  } */
  /* #myDiv34567{
    right: 3%;
    top : 180% !important;
    z-index: 3;
  } */
}
#myDivUmer{
  padding: 0px 0px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  /* top : 30%; */
  display : none;
  width: 46% !important;
  height : 49% ;
  background-color: white;
  /* margin-left : 30%; */
  border: 4px solid grey;
  bottom: 0px;
  right: 8%;

}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
 
  #myDivUmer{
    height:320px;
    width: 200px;
    margin-left : 165px;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
 
  #myDivUmer{
    height:300px !important;
    width: 300px !important;
    margin-left : 11%;
  }
}
@media  only screen and (min-width: 320px) and (max-width: 390px){
  .logo-img{
       margin-left: 63px;
       width : 234px !important;
  }
  .nav-icon-mobile45{
   /* padding-left: 70px !important;
       padding-right: 0px !important; */
    right: 13px;
    position: absolute;
    padding-top: 28px !important;
 }
}


@media  only screen and (min-width: 820px) and (max-width: 129px){
  .change-header-color33{
    display : block !important;
  }
 
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
  
.display-6{
  display: none;
}
  @media  only screen and (min-width: 120px) and (max-width: 429px){
    .dispaly-block{
      display: none;
    }
    .display-6{
      display: block;
    }
   .HeaderMainImp {
      width: 103% !important;
  }
  }
  @media  only screen and (min-width: 120px) and (max-width: 629px){
   
    .stext-104{
      width: 100% !important;
    }
  }




  .loader-1{
    position : fixed  ;
    left: 45%;
    top:20%;
    z-index: 9999;
  }


  @media  only screen and (min-width: 120px) and (max-width: 529px){
  .loader-1{
    position : fixed  ;
    left: 35%;
    top:20%;
    z-index: 9999;
  }
  .rgjrgou{
    width: 95% !important;
    margin: 10px 1px 50px 10px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .rgjrgou1{
    width: 90% !important;
    margin: 10px 0px 50px 19px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .site-navbar {
    width: 97% !important;
}
  }
  .ci11{
    visibility: hidden;
  }





.adjustTheSubmitButton{
    /* position: absolute;
    right : 147px;
    bottom:  7px; */
    /* background-color: #343443;  */
    border: none;
    color: black;
    padding: 1px 10px;
    border-radius:  40px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;}



    .messageStyle{
      background: skyblue;
      display: inline-block;
      padding: 10px 15px !important;
      border-radius: 10px;
      margin: 15px !important;
      overflow-wrap:anywhere;
      min-width: 40px !important;
      max-width: 550px !important;
  
  }
  /* .chatControls{
    display: flex;
    position: fixed;
    width: 34%;
    height: 50px;
    bottom: 0;
}
.chatControls div{
    padding-left: 30px  !important;
    border:2px solid #000;
    border-radius: 10px;
    width: 87%;
}

    .chatControls label{
        position: absolute;
        right : 147px;
        bottom:  -2px;
        background-color: skyblue;
        border: none;
        color: black;
        padding: 5px 10px;
        border-radius:  40px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    } */
    .wsdsd{
      padding-top: 7px !important;
      margin-left: 30px;
       background-color: transparent !important;
    }
    .rlkgeglktgkl{
      background-color: rgb(254, 189, 105);
      border-bottom-right-radius: 29px;
      border-top-right-radius: 29px;
      width: 48px !important;
      height:44px !important;
      /* margin-left: -15px; */
    margin-top: 5px;
    padding: 6px;
    color : black;
    }
    
.erkrkk:hover .dropdown123 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}
.erkrkk:hover .dropdown1234 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}

.dropdown123 {
  position: absolute;
  left: 25%;
  top: 100% !important;
  width: 55%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
}

 .dropdown123 li {
	display: block;
	margin-left: 0;
}

 .dropdown123 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown123 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}

.dropdown1234 {
  position: absolute;
  left: 40%;
  top: 100% !important;
  width: 30%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
  padding: 0px 30px;
}

 .dropdown1234 li {
	display: block;
	margin-left: 0;
}

 .dropdown1234 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown1234 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}
.fgilklk{
  font-weight: bold;
  padding   : 0px 30px;
  transition: all 0.3s;
  cursor: pointer !important;
}
.fgilklk1{
  padding   : 8px 25px;
  /* transition: all 0.3s;
  cursor: pointer !important; */
  /* border-bottom: 1px solid grey; */
}
.elfkefkl{
  padding   : 5px 10px !important;
  font-size: 13px !important;
  font-weight: bold;
  margin :  0px !important;
}
.fgilklk:hover{
  background-color: rgb(230, 225, 225) !important;
  color :rgb(36, 163, 181) !important
}
.dffkl{
  display:  none;
}

.rtrtrtt2323{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .fdgrg{
    position: fixed;
    bottom: 43px;
    left: 29% !important;
  }
  .rtrtrtt{
    display: none !important;
  }
  .rtrtrtt2323{
    display: block !important;
  }
  .site-block-top-search .icon {
      right: -3%;
      top: 23% !important;
       }
       .site-block-top-search input{
         padding-left: 0px !important;
       }
       .wsdsd {
        padding-top: 0px !important;
        margin-left: 14px !important;
       }
       .wsduhu{
         display:  none !important;
       }
       .dffkl{
        display:  block !important;
      }
      }
      .dfidfpfdfp{
        padding-left: 52px !important;
      }
      @media only screen and (min-width: 1400px) and (max-width: 1600px) {
        .site-block-top-search .icon{
          right: -1.6% !important;
        }
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
      }
      @media only screen and (min-width: 600px) and (max-width: 1600px) {
        .jkkukk{
          height: 500px !important;
          /* object-fit: contain; */
          width: 100% !important;
        }
      }
      @media only screen and (min-width: 200px) and (max-width: 500px) {
        .jkkukk{
          max-height: 200px !important;
          min-height: 200px !important;
          /* object-fit: contain; */
        }
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
        .site-block-top-search .icon{
    right: -1.8% !important;
  }
}
@media only screen and (min-width: 1032px) and (max-width: 1199px) {
  .site-block-top-search .icon{
    right: -2.1% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 991px) {
  .site-block-top-search .icon{
    right: -3.4% !important;
  }
}

#myDiv1234{
  padding: 30px 10px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  top : 11%;
  display : none;
  width: 21% !important;
  right:8%;
  height : 31% ;
  background-color: white;
  box-shadow: grey 0px 2px 6px;
  /* margin-left : 30%; */
  /* border: 4px solid grey; */


}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
  #myDiv1234{
    height:320px;
    width: 200px;
    right: 9%;
    /* margin-left : 165px; */
  }}

  
@media  only screen and (min-width: 170px) and (max-width: 529px){
  
  #myDiv1234{
    height:268px !important;
    width: 300px !important;
    margin-left : 11%;
  }}


  .fjfklflkdkld{
    padding: 10px 30px;
    background: red;
    color: white;
    border: transparent;
    border-radius: 7px;
    width: 90%;
    margin : 5px 0px
  }
  .ul-li-signup > li{
   list-style: none;
  }
  .ul-li-signup >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  10px 0px;
  }
  .ul-li-signup1 > li{
   list-style: none;
  }
  .ul-li-signup1 >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  8px 0px;
  }
  .topcontainershow::-webkit-scrollbar {
    /* width: 20px; */
    height: 5px;
    background-color: darkgrey;
    color : black;
    scrollbar-width: thin;
    border-radius: 100px;
    scrollbar-color: #D4AA70 #e4e4e4;
}
  .topcontainershow::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: rgb(36, 163, 181);
    border: 6px solid rgba(0,0,0,0.2);
}
/* {
    scroll-bar-color: #6969dd #e0e0e0;
    scroll-bar-width: thin;
    scroll-bar-gutter: stable;
  } */